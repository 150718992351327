import React, { useState } from 'react';
import { TocEntryData } from 'src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  entry: TocEntryData;
}

export default function TocEntry(props: Props): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);
  const hasChildren = props.entry.children.length !== 0;

  return (
    <>
      <div className="flex justify-between my-2">
        <a className="flex-grow py-1 hover:text-teal-500" href={'#' + props.entry.slug}>{props.entry.name}</a>
        {hasChildren && (
          <button className={`focus:outline-none ${expanded ? 'text-gray-900' : 'text-gray-600'}`} onClick={() => setExpanded(!expanded)}>
            <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
          </button>
        )}
      </div>
      {expanded && (
        <div className="ml-4">
          {props.entry.children.map(child => (
            <TocEntry entry={child} key={child.slug} />
          ))}
        </div>
      )}
    </>
  );
}
