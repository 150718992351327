import React from 'react';
import Slugger from 'github-slugger';
import { TocEntryData } from 'src/types';
import TocEntry from 'src/components/TocEntry';
import clsx from 'clsx';

interface Props {
  headings: GatsbyHeading[];
  title: string;
}

interface GatsbyHeading {
  depth: number;
  value: string;
}

interface ParentReference {
  heading: TocEntryData;
  depth: number;
}

function transformHeadings(headings: GatsbyHeading[]): TocEntryData[] {
  const slugger = new Slugger();
  const parents: ParentReference[] = []; // acts like a stack
  const result: TocEntryData[] = [];
  let prevDepth: number;
  let prevNode: ParentReference;

  headings.forEach(heading => {
    const node: TocEntryData = {
      name: heading.value,
      slug: slugger.slug(heading.value),
      children: [],
    };

    if (prevDepth === undefined) {
      prevDepth = heading.depth;
    }

    if (heading.depth > prevDepth) {
      parents.push(prevNode);
    }

    while (heading.depth < prevDepth && parents.length > 0) {
      prevDepth = parents.pop()?.depth || 0; // never uses default
    }

    if (parents.length === 0) {
      result.push(node);
    } else {
      parents[parents.length - 1].heading.children.push(node);
    }

    prevDepth = heading.depth;
    prevNode = { heading: node, depth: heading.depth };
  });

  return result;
}

export default function TableOfContents(props: Props): JSX.Element {
  const headings: TocEntryData[] = transformHeadings(props.headings);

  return (
    <div
      className={clsx(
        'flex-shrink-0',
        'w-80',
        'bg-gray-200',
        'rounded',
        'px-3',
        'mr-10',
        'mt-3',
        'sticky',
        'bottom-0',
        'top-4',
        'hidden',
        'lg:block',
        'max-h-90',
        'overflow-y-auto'
      )}
    >
      <div className="font-bold mt-2 text-lg text-gray-800">{props.title}</div>
      {headings.map(heading => (
        <TocEntry entry={heading} key={heading.slug} />
      ))}
    </div>
  );
}
