import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import * as DesignSystem from 'src/DesignSystem';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import SEO from 'src/components/SEO';
import Box from 'src/components/Box';
import Tag from 'src/components/Tag';
import TableOfContents from 'src/components/TableOfContents';

interface Props {
  data: {
    mdx: {
      body: string;
      headings: [
        {
          depth: number;
          value: string;
        }
      ];
      frontmatter: {
        title: string;
        version: string;
        date: string;
      };
    };
  };
}

export default function ArticleLayout(props: Props): JSX.Element {
  const mdx = props.data.mdx;

  return (
    <BasicLayout>
      <SEO title={mdx.frontmatter.title} />
      <div className="max-w-6xl p-4 mx-auto text-gray-900 flex items-start">
        <TableOfContents
          headings={mdx.headings}
          title={mdx.frontmatter.title}
        />
        <div className="flex-grow">
          <h1 className="font-bold text-4xl">{mdx.frontmatter.title}</h1>
          <div className="flex justify-between items-baseline mb-3">
            <span className="italic text-gray-700">{mdx.frontmatter.date}</span>
            <Tag>{mdx.frontmatter.version}</Tag>
          </div>
          <MDXProvider
            components={{
              Box,
              Tag,
              p: DesignSystem.Paragraph,
              h1: DesignSystem.H1,
              h2: DesignSystem.H2,
              h3: DesignSystem.H3,
              h4: DesignSystem.H4,
              h5: DesignSystem.H5,
              h6: DesignSystem.H6,
              strong: DesignSystem.Strong,
              a: DesignSystem.A,
              table: DesignSystem.Table,
              th: DesignSystem.Th,
              td: DesignSystem.Td,
              blockquote: DesignSystem.Blockquote,
              li: DesignSystem.Li,
              ul: DesignSystem.Ul,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </BasicLayout>
  );
}

export const pageQuery = graphql`
  query ArticleQuery($id: String) {
    mdx(id: { eq: $id }) {
      headings {
        depth
        value
      }
      body
      frontmatter {
        title
        version
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
